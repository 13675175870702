import navigateCompatible from './navigate-compatible';
import navigateUix from './navigate-uix';

import history from 'core/appHistory';

wx.navigateTo = function (params){
  if(history.router?.type == "portal"){
    navigateUix.navigateTo(params);
  }else{
    navigateCompatible.navigateTo(params);
  }
};

wx.navigateBack = function (params){
  if(history.router?.type == "portal"){
    navigateUix.navigateBack(params);
  }else{
    navigateCompatible.navigateBack(params);
  }
};

wx.redirectTo = function (params){
  if(history.router?.type == "portal"){
    navigateUix.redirectTo(params);
  }else{
    navigateCompatible.redirectTo(params);
  }
};

export {};
