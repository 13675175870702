import {message,notification} from 'antd';

import wx from "../../../../core/api/wxApi";


if(!document.getElementById("pcx-page-root")){
  let pcxPageRoot = document.createElement('div');
  pcxPageRoot.classList.add('pcx-page');
  pcxPageRoot.setAttribute('id', "pcx-page-root");
  document.body.appendChild(pcxPageRoot);
}


message.config({getContainer:()=>{return document.querySelector("#pcx-page-root")}});
notification.config({getContainer:()=>{return document.querySelector("#pcx-page-root")}});
wx.initenv = function(pageImpl){
  pageImpl.hint = function(msg,type="info",duration= 3000){

    message[type]({
      content:msg,
      duration:duration/1000,
      style:{
        position:"relative",
        top:"50px"
      }
    });
  };

  wx.showToast = function(params){
    let {title="",icon="info",duration=1500,mask=false}=params;
    message[icon]({
      content:title,
      key:"wx_show_toast",
      duration:duration/1000,
      style:{
        position:"relative",
        top:"50px"
      }
    });
  };


  wx.hideToast = function(){
    message.destroy("wx_show_toast");
  };

}
