import wx from "core/api/wxApi";
import {doThen,doCatch,isX5App,resolveLocalFile} from 'core/utils';
import {message} from "antd";
message.config({getContainer:()=>{return document.querySelector("#pcx-page-root")}})
/***
 *OBJECT参数说明：

 属性	类型	默认值	必填	说明
 title	string		是	提示的内容
 mask	boolean	false	否	是否显示透明蒙层，防止触摸穿透
 success	function		否	接口调用成功的回调函数
 fail	function		否	接口调用失败的回调函数
 complete	function		否	接口调用结束的回调函数（调用成功、失败都会执行）
 示例代码

  wx.showLoading({
    title: '加载中',
  })

  setTimeout(function () {
    wx.hideLoading()
  }, 2000)
 *
 */

function showLoading(params){
  let {title=" ",icon="loading",duration=300000,mask=false}=params;

  message['loading']({
    content:title,
    duration:duration/1000,
    style:{
      position:"relative",
      top:"50px"
    },
    key:"wx_show_loading"
  });
  return doThen(params,{errMsg:"showLoading:ok"});
}

function hideLoading(params){
  message.destroy("wx_show_loading");
  return doThen(params,{errMsg:"hideLoading:ok"});
}

wx.showLoading = showLoading;
wx.hideLoading = hideLoading;

export {}


