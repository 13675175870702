import history from "core/appHistory";



function url() {
  let l = window.location;
  return ((l.protocol === "https:") ? "wss://" : "ws://") + l.host + l.pathname + s;
}

export default class HotRenderClient{
  constructor(serviceName,contextName) {
    this.serviceName = serviceName;
    this.contextName = contextName;
    this.reConnectionCount = 0;
    this.connect();
  }
  getUrl(serviceName,contextName){
    let l = window.location;
    let pathName =  (this.serviceName ? "/" + this.serviceName : "") + "/" + this.contextName;
    return ((l.protocol === "https:") ? "wss://" : "ws://") + l.host + pathName + "/hotRender";
  }

  connect(){
    this.socket = new WebSocket(this.getUrl());

    this.socket.addEventListener('open',  (event) =>{
      console.log('热更新连接成功！');
      this.reConnectionCount = 0;
    });

    this.socket.addEventListener('close',  (event) =>{
      console.log('热更新断线重连', event.reason);
      if(this.reConnectionCount < 5){
        setTimeout(() =>{
          this.connect();
        }, 1000);
      }
    });

    this.socket.addEventListener('error',  (event) =>{
      console.error('热更新通信异常: ', event.message);
      this.reConnectionCount++;
      this.socket.close();
    });

    this.socket.addEventListener('message',  (event) =>{
      let  message = JSON.parse(event.data);
      let currentPageInstance  = getCurrentReactPage();
      //新门户
      if(history.type == "uix"){
      }else if(currentPageInstance && message.route == currentPageInstance.wxPageDeclaration.route) {
        let location = currentPageInstance.wxPageDeclaration.location;
        wx.render(location,"REPLACE");
      }
    });
  }
}
