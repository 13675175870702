import wx from "../../../../core/api/wxApi";
import {Modal} from 'antd';
import {doThen} from "../../../../core/utils";

/***
 *OBJECT参数说明：

	参数				类型			必填			说明
	title			String		是		提示的标题
	content			String		是		提示的内容
	showCancel		Boolean		否		是否显示取消按钮，默认为 true
	cancelText		String		否		取消按钮的文字，默认为"取消"，最多 4 个字符
	cancelColor		HexColor	否		取消按钮的文字颜色，默认为"#000000"
	confirmText		String		否		确定按钮的文字，默认为"确定"，最多 4 个字符
	confirmColor	HexColor	否		确定按钮的文字颜色，默认为"#3CC51F"
	success			Function	否		接口调用成功的回调函数，返回res.confirm为true时，表示用户点击确定按钮
	fail			Function	否		接口调用失败的回调函数
	complete		Function	否		接口调用结束的回调函数（调用成功、失败都会执行）
 *
 */
function showModal(params){
	let {title="",content="",showCancel=true,cancelText="取消",cancelColor="#000000",
    confirmText="确定",confirmColor="#3cc51f"}=params;


  let currentPage = window.getCurrentReactPage();
  if(currentPage){
    title = currentPage.i18n(title);
    content = currentPage.i18n(content);
    cancelText = currentPage.i18n(cancelText);
    confirmText = currentPage.i18n(confirmText);
  }

  return new Promise(resolve => {
    if(showCancel){
      Modal.confirm({
        content:content,
        title:title,
        cancelText:cancelText,
        okText:confirmText,
        getContainer:()=>{return document.querySelector("#pcx-page-root")},
        destroyOnClose:true,
        onOk:function(){
          resolve({errMsg:"showModal:ok",confirm:true,cancel:false});
        },
        onCancel:function(){
          resolve({errMsg:"showModal:ok",confirm:false,cancel:true});
        }
      });
    }else{
      Modal.info({
        getContainer:()=>{return document.querySelector("#pcx-page-root")},
        content:content,
        title:title,
        okText:confirmText,
        destroyOnClose:true,
        onOk:function(){
          resolve({errMsg:"showModal:ok",confirm:true,cancel:false});
        },
        onCancel:function(){
          resolve({errMsg:"showModal:ok",confirm:false,cancel:true});
        }
      });
    }

  }).then(res=>{
    if(res){
      doThen(params,res);
    }
  });
}

wx.showModal = showModal;

export {}
