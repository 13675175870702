import wx from '../../../../core/api/wxApi';
import router from "../../../../core/router";
import PcxPageModal from "../../../components/PcxPageModal/PcxPageModal";
import {redirectTo,navigateTo,navigateBack,_formatUrl,checkUrl} from '../../../../core/api/ui/navigate';
import React, {cloneElement} from 'react';
import {get} from 'lodash';
import ReactDOM from 'react-dom';
import {doCatch, doThen,normalizeUrl} from "../../../../core/utils";
import uniqueId from 'lodash/uniqueId';
import last from 'lodash/last';
import history from "../../../../core/appHistory";
import { parsePath } from 'history';
import loader from 'core/framework/loader'
import URI from 'urijs';

// 当前文件主要兼容 老pc门户 打开关闭页面的逻辑
// pcx中navigateTo采用对话框打开
// pcx中navigateBack采用关闭对话框
function _navigateTo(params){
  loader.menuResolved.then(()=>{
    let _originUrl = params.url;
    if("page" == params.openType){
      if(window.justep && window.justep.Shell && window.justep.Shell.impl){
        let _url = new URI(_originUrl);
        _url.pathname(_url.pathname() + ".w");
        justep.Shell.showPage(_url.toString()).then(()=>{
          doThen(params);
        });
      }else{
        let _url = _formatUrl(params);
        wx.render(parsePath(_url),"PUSH").then(() =>{
          doThen(params);
        });
      }
    }else{
      openPageByModal(params).then(function(){
        doThen(params);
      });
    }
  })
};

/**
 * pcx 中通过pc门户打开pcx页面采用redirectTo方案 第一个页面没有原始页面redirectTo有问题 直接利用render渲染
 *
 */
function _redirectTo(params){
  loader.menuResolved.then(()=>{
    let _url = _formatUrl(params);
    if(getCurrentReactPages().length == 0){
      wx.render(parsePath(_url));
    }else if(params){
      wx.render(parsePath(_url),"REPLACE");
    }
  });
};

/**
 * pc门户中 widget 或者 pageFrame打开的modal 需要明确传递 currentpage
 * 否则计算出来的是路由的 currentpage
 *
 */
function _navigateBack(params){
  let currentPages = router.currentPages;
  let currentPage = params.currentPage || last(currentPages);
  if(currentPage && currentPage.modals && currentPage.modals.length > 0 ){
    let pageModalInfo = currentPage.modals.pop();
    pageModalInfo.instance.setState({visible:false});
    ReactDOM.unmountComponentAtNode(pageModalInfo.node);
    pageModalInfo.node.remove();

    let parentPageModalInfo = last(currentPage.modals);
    if(parentPageModalInfo && parentPageModalInfo.instance && parentPageModalInfo.instance.page &&
      parentPageModalInfo.instance.page.onShow && (typeof parentPageModalInfo.instance.page.onShow == "function")){
      parentPageModalInfo.instance.page.onShow();
    }else if (currentPage.onShow && (typeof currentPage.onShow == "function")) {
      currentPage.onShow();
    }
  }else if(window.justep && window.justep.Shell && window.justep.Shell.impl){
	  window.justep.Shell.closePage();
  }else {
	  //解决第三方门户通过iframe嵌入pcx页面后，调用关闭时通知第三方门户
	  if (window && window.parent && window.parent.postMessage){
		  let msg = {type: "shell", name: "navigateBack", args: {}};
		  if (currentPage){
			  msg.args.pageId = currentPage.pageId;
			  msg.args.pagePath = currentPage.pagePath;
		  }
		  window.parent.postMessage(JSON.stringify(msg), '*');
	  }
	  history.back();
  }
};





function openPageByModal(params) {
  let _url = _formatUrl(params);
  if (checkUrl(_url)) {
    return router.resolve(parsePath(_url),params.currentPage).then((component) => {
      let currentPage = params.currentPage ||  window.getCurrentReactPage();

      if(!currentPage){
        wx.currentWidgetPage =  currentPage = {pageId:"pcx-page-root"};
      }
      let currentPageId = currentPage.pageId;
      let modalId = uniqueId('page_modal' + currentPageId);
      let target = createModalPageContainer(currentPageId,modalId);
      component = cloneElement(component, {
        ownerPage: currentPage,
        ownerModel:params.currentModel,
        _openByNavigateApi:true,
        configContext:currentPage?.configContext   //解决navigateTo没有传递语言的问题
      });
      let modalProps = (typeof params.modal == "object")?params.modal:JSON.parse(params.modal || "{}");
      modalProps.currentTheme = params.currentPage && params.currentPage.getCurrentTheme && params.currentPage.getCurrentTheme();
      modalProps.currentLocale = params.currentPage && params.currentPage.getCurrentLanguage && get(params.currentPage.getCurrentLanguage(),'resource.components.antdpro');
      let pageModal = ReactDOM.render(React.createElement(
        PcxPageModal, modalProps , component
      ), target);

      let page = pageModal.page;
      page.renderRoot = target;
      currentPage.modals = currentPage.modals || [];
      currentPage.modals.push({instance:pageModal,node:target});
      page.wxPageDeclaration.route = _url.replace(/^\//, "");
      page.id = modalId;

      if (currentPage.onHide && (typeof currentPage.onHide == "function")) {
        currentPage.onHide();
      }

      /*if (page.onLoad && (typeof page.onLoad == "function")) {
        page.onLoad(page.props.options);
      }*/
      if (page.onShow && (typeof page.onShow == "function")) {
        page.onShow();
      }

      if (page.onReady && (typeof page.onReady == "function")) {
        page.onReady();
      }
    });
  } else {
    doCatch(params, {
      errMsg: "switchTo:fail page \"" + params.url + "\" is not found"
    });
  }
}

function createModalPageContainer(pageId,modalId) {
  let modalContainer = document.getElementById(pageId);
  let currentPages = router.currentPages;

  let el = document.getElementById(modalId);
  if (el) {
    return el;
  }
  el = document.createElement('div');
  el.classList.add('page_container');
  el.classList.add('WxPage');
  el.setAttribute('id', modalId);
  modalContainer.appendChild(el);
  return el
}

export default {
  navigateTo:_navigateTo,
  navigateBack:_navigateBack,
  redirectTo:_redirectTo,
  openPageByModal:openPageByModal,
  _formatUrl

};
