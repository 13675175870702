import * as antd from 'antd';
antd.message.warn = antd.message.warn || antd.message.warning;
window.antd = antd;
const { useToken } = antd.theme;
export const CssTheme = () => {
  let { token } = useToken();
  const globalVars = `html {
    --ant-color-primary: ${token.colorPrimary};
    --ant-color-primary-hover: ${token.colorPrimaryHover};
    --ant-control-outline: ${token.controlOutline};
    --ant-color-link: ${token.colorLink};
    --ant-screen-sm: ${token.screenSM};
    --ant-color-link-active: ${token.colorLinkActive};
    --ant-color-link-hover: ${token.colorLinkHover};
  }`;
  return <style>{globalVars}</style>;
};
