import navigateCompatible from './navigate-compatible';
import {doCatch, doThen,normalizeUrl} from "core/utils";
import {navigateTo, redirectTo, navigateBack, checkUrl,_formatUrl} from 'core/api/ui/navigate';
import history from 'core/appHistory';
import router from "core/router";
import CryptoRequest from "../../../../core/api/network/crypto-request";
import loader from 'core/framework/loader';
import {last} from "lodash";

function _navigateTo(params){
  loader.menuResolved.then(()=>{
    let _originUrl = params.url;
    if("page" == params.openType){
      // uix主要逻辑
      let currentUrl = history.location.pathname + history.location.search;
      if(currentUrl != _originUrl){
        _originUrl = CryptoRequest.parse(_originUrl).toString();
        history.push(_originUrl);
        doThen(params);
      }
    }else{
      //兼容性保留 后续可以删除
      navigateCompatible.openPageByModal(params).then(function(){
        doThen(params);
      });
    }
  })
};

function _redirectTo(params){
  loader.menuResolved.then(()=>{
    let _originUrl = params.url;
    _originUrl = CryptoRequest.parse(_originUrl).toString();
    history.replace(_originUrl);
    doThen(params);
  });
};

function _navigateBack(params){
  let currentPages = router.currentPages;
  let currentPage = params.currentPage || last(currentPages);
  if(currentPage && currentPage.modals && currentPage.modals.length > 0 ){
    //兼容性保留 后续可以删除
    navigateCompatible.navigateBack(params);
  }else {

    /**
     通过门户打开的页面

     第一次 navigateBack 场景 history 可能为1 这种场景一定是window.open打开的页面 iframe方案 history和父是保持一致的 不会是1

     场景1 嵌入页方式打开的 portalType embedded
        发送postMessage 让打开方决定

     场景2 直接通过门户打开的 页面
        直接关闭当前页面 可能回到门户首页
     */
    if(history.canGoBack()){
      history.go(-1);
    }else {
      if(history.router.portalType == "embedded"){
        //iframe 场景
        if(window.parent !== window){
          sendMessage(window.parent,currentPage);
        }else if(window.opener){
          sendMessage(window.opener,currentPage);
          window.close();
        }else {
          history.go(-1);
        }
      }else {
        history.router.action.closeAllPages();
      }
    }
  }
};


function sendMessage(owner,currentPage){

    let msg = {type: "shell", name: "navigateBack", args: {}};
    if (currentPage){
      msg.args.pageId = currentPage.pageId;
      msg.args.pagePath = currentPage.pagePath;
    }
    owner.postMessage(JSON.stringify(msg), '*');
}

export default {
  navigateTo:_navigateTo,
  navigateBack:_navigateBack,
  redirectTo:_redirectTo
};
